import React from 'react';
import '../styles/Services.css';
import {KwartaPadalaServices} from '../utils/Data';

const MLUsServicesComponent = () => {

  const KwartaPadala = () => {
    return KwartaPadalaServices?.map((item, key) => {
          return (
            <li className="list-item" role="listitem" key={key} tabIndex={0}>
              <span>{ item }</span>   
            </li>
          )
      })
    
  }
  return (
    <div className='ml-us-services' role='main'dir='ltr'>
      <div className="parallax" id='parallax'>
          <div className='parallax-img'>
              <img role='img' alt='chevron down' title='chevron down' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVR4nO3UXQrDIBAEYE/QJrRHyJUEd3bE2wV6ktJbFaENKbXUv7w5j7rOh5BozMjIoVHVk6pKCGHq0RdCmEREY++2SNKp6oPkCmBuAQDMsefV57YNa+15t1EN4RNYY+/XAID3wE1ErqUAdue995eswVwIuUAthFKgFEItkAuhFfgHoRfwCyK5dAVS3z+Ae68fNwnhiBuk3iSSrtcbNzJimvMExOah9xXqaCIAAAAASUVORK5CYII="></img>
          </div>
      </div>
      <div className="content" id='main-content'>
       <section className="heading--wrapper">
        <div>
          <div className='heading'>
            <br />
            <h1 className='text-center'>Products and Services</h1>
            <br />
              <p>
              <h2 style={{ display:"inline" }}>M Lhuillier</h2> <span>is ready to be of service to every Filipino family not just in the Philippines but also in the United States!
                <br />Filipinos from overseas can avail of M Lhuillier’s fast and reliable financial services through M Lhuillier branches in the U.S. as well as online through ML Remit.

                <br />That’s not all! M Lhuillier also offers our US-based KapaMLya a selection of fine jewelry through one of the Philippines’ most trusted jewelers M Lhuillier Jewellers.

                <br />Read on to know more about how M Lhuillier can help you with your financial needs:</span>
              </p>
            </div>
        </div>
       </section>
       <section className="kwarta-padala--wrapper">
            <div className='kwarta-padala'>
              <h3>Kwarta Padala</h3>
              <p>
                Send money from the U.S. to the Philippines through M Lhuillier’s Kwarta Padala, the leading remittance service both in the Philippines and abroad.

                <br />Avail of money sending services anytime, anywhere with a wide network of branches and a reliable technology-based system.

                <br />One can send money through M Lhuillier branches in California and Nevada for as low as USD 4.99. Recipients from the Philippines can receive the money through over 3,000 branches nationwide without having to pay extra fees. It’s that affordable!
              </p>
              <div>
                <p>ML US Branches as of March 2022</p>
                <ul className='services' role="list">
                  <KwartaPadala/>
                </ul>
              </div>
            </div>
       </section>
       <section className='ml-remit--wrapper'>
          <div className='ml-remit'>
            <h3>ML Remit</h3>
            <p>
            Money remittance services are made easier and faster with ML Remit, an online platform for sending money to the Philippines.
            <br />You don’t have to go to physical branches just to make your transactions, just download the ML Remit App or log in to the ML Remit website for a fast, convenient, and safe way to send money instantly.

              <br />Send money using your checking account, credit card, or debit card and only pay a fixed rate of USD 4.99. It’s the most affordable way to send money to the Philippines!
            </p>
          </div>
       </section>
       <section className='quick-cash--wrapper'>
          <div className='quick-cash'>
            <h3>Quick Cash Loans</h3>
            <p>
            One of the most widely available and trusted lending services in the Philippines is also available in the U.S.
            <br />M Lhuillier’s Quick Cash Loans provide ways for your immediate financial needs. Get the value you deserve for your precious gold and diamond 
            jewelry with guaranteed high appraisal rates and low interest rates.
            <br />You can be assured of safe and secure transactions with tight security measures inside every branch and secure storage of collateral items.
            <br />Head on to M Lhuillier branches in West Covina, Sacramento, and Concord to avail of M Lhuillier’s Quick Cash Loans.
            </p>
          </div>
       </section>
       <section className='jewelry--wrapper'>
          <div className='jewelry'>
            <h3>Jewelry</h3>
            <p>
            Invest in lifestyle pieces from one of the Philippines’ most trusted names in fine jewelry. M Lhuillier Jewellers offers exquisite handcrafted jewelry for the discerning taste.
            <br />Choose from a quality selection of diamond sets, gemstones, pearls, wedding rings, earrings, rings, bracelets, and chokers through any M Lhuillier branch in the U.S.
            </p>
            <p>
            Visit <a href="https://www.mlremit.com" target='_blank' role='link' title="ml remit" aria-label='ml remit' >www.mlremit.com</a> to know more about M Lhuillier services in the United States. Get in touch with M Lhuillier U.S.A. 
            through the toll-free number (877) 688-4588 or email <a href="#">support@mlhuillierusa.com</a>.
            </p>
          </div>
       </section>
      </div>
    </div>
  )
}

export default MLUsServicesComponent;