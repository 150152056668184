
import React from 'react';
import { Button, Container, Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import Flag from '../../assets/images/usa-flag.jpg';
import '../../styles/Navbar.css';
import { useLocation } from 'react-router-dom';

const NavbarComponent = () => {
  const location = useLocation();

  const NavItems = [
    {
      title: "home",
      name:"Home",
      link:'/home',
      isDropdown: false
    },
    {
      title: "Products & Services",
      name:"Products & Services",
      link:'#',
      isDropdown: true,
      items: [
        {
          title: "Login ML Remit",
          name:"Login | ML Remit",
          link:'/product-services/login-ml-remit',
        },
        {
          title: "ml usa services",
          name:"ML USA Services",
          link:'/ml-usa-services',
        },
        {
          title: "ML Philippines Services",
          name:"ML Philippines Services",
          link:'https://mlhuillier.com/',
        },
      ]
    },
    {
      title: "ml usa branches",
      name:"Branches",
      link:'/branches',
      isDropdown: false
    },
    {
      title: "faq",
      name:"faq",
      link:'/faq',
      isDropdown: false
    },
    {
      title: "ml blog",
      name:"blog",
      link:'/blog',
      isDropdown: false
    },
    {
      title: "contact",
      name:"contact",
      link:'/contact',
      isDropdown: false
    },
    {
      title: "terms & conditions",
      name:"terms & conditions",
      link:'/terms-and-conditions',
      isDropdown: false
    },
  ];

  const NavLinks = () => {
    return NavItems?.map((item, key) => {
      return item.isDropdown ? 
       (<>
          <NavDropdown title={item.name} id="basic-nav-dropdown" key={key} aria-haspopup="true">

            {
              item.items?.map((dropdownItem, key) => {

                return (
                  <NavDropdown.Item href={dropdownItem.link} 
                    title={dropdownItem.title} 
                    className={isActive(dropdownItem.link)} 
                    key={key} 
                    aria-label={`${dropdownItem.name} tab`}>
                    { dropdownItem.name }
                  </NavDropdown.Item>
                )
              })
            }
          </NavDropdown>
        </>)
      : (<Nav.Link href={item.link} title={item.title} key={key} className={isActive(item.link)} aria-label={`${item.name} tab`}>{item.name}</Nav.Link>)
    })
  }

  const isActive = (link) => {
    return link === location.pathname ? 'active' : '';
  }

  return (
    <Navbar expand="lg" data-bs-theme="dark" aria-label='navigation' bg='black' fixed='top' id='navigation'>
      <Container expand="lg" fluid id='container'>
        <div>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Brand href="/home" title='home' tabIndex="0" aria-label='back to home'>
            <img src={Flag} alt="USA flag" title='USA flag' role='img' aria-label='site home' width={70} style={{ maxWidth:"100%", height:"auto" }}/>
          </Navbar.Brand>
        </div>
        <Navbar.Offcanvas id="basic-navbar-nav" className="text-bg-dark">
          {/* <Offcanvas.Header closeButton title='Close' aria-label='close sidebar navigation'/> */}
          <Offcanvas.Header title='Close'>
            <Button className='btn-close' aria-label='close sidebar navigation'/>
            </Offcanvas.Header>
          <Offcanvas.Body className='justify-content-end' id='sidebar-content'>
            <Nav id='nav'>
              <NavLinks/>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
       <div className='px-2'>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0,0,256,256">
            <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none"><g transform="scale(5.33333,5.33333)"><path d="M20.5,6c-7.99037,0 -14.5,6.50964 -14.5,14.5c0,7.99036 6.50963,14.5 14.5,14.5c3.45636,0 6.63371,-1.22096 9.12891,-3.25l9.81055,9.81055c0.37623,0.39185 0.9349,0.54969 1.46055,0.41265c0.52565,-0.13704 0.93616,-0.54754 1.07319,-1.07319c0.13704,-0.52565 -0.0208,-1.08432 -0.41265,-1.46055l-9.81055,-9.81055c2.02904,-2.4952 3.25,-5.67255 3.25,-9.12891c0,-7.99036 -6.50963,-14.5 -14.5,-14.5zM20.5,9c6.36905,0 11.5,5.13096 11.5,11.5c0,3.10261 -1.2238,5.90572 -3.20898,7.9707c-0.12237,0.08994 -0.23037,0.19794 -0.32031,0.32031c-2.06499,1.98518 -4.86809,3.20898 -7.9707,3.20898c-6.36905,0 -11.5,-5.13096 -11.5,-11.5c0,-6.36904 5.13095,-11.5 11.5,-11.5z"></path></g></g>
          </svg>
       </div>
      </Container>
    </Navbar>
  )
}


export default NavbarComponent;