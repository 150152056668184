import React from 'react';
import '../styles/Login.css';
import MLRemitBanner from '../assets/april/login banner.jpg';
import {Button} from 'react-bootstrap';

const LoginComponent = () => {

   const Login = () => {
        window.open("https://send.uniteller.com/jsps/LoginPage.action?wlpCode=TUxIVUlMTElFUkl0U2loTnNZekI0OTVRUTZKNU1YcjAvTzFyST0=", "_self");
   } 

   const SignUp = () => {
        window.open("https://send.uniteller.com/jsps/Signup.action?request_locale=en&wlpCode=TUxIVUlMTElFUkl0U2loTnNZekI0OTVRUTZKNU1YcjAvTzFyST0=", "_self");
   }

  return (
    <div className="login" role='main' dir='ltr' aria-hidden="false">
       <section>
        <div className='login-form--wrapper'>
        <h1 className='text-center pt-5'>Download ML Remit App Now!</h1>
                <div className='login--banner-wrapper'>
                    <div className='login--banner'>
                        <img src={MLRemitBanner} alt="ml remit app on mobile phone" title='ml remit app on mobile phone'/>
                    </div>
                    <div className="buttons-wrapper" id='main-content'>
                        <div className='buttons'>
                            <div role='presentation'>
                                <p dir='ltr'>If you already have an account, log in <br /> here:</p>
                                <Button className='ml-remit-btn' title='ml remit login' id='login' 
                                        variant='dark' size='sm' role='button' aria-description='ml remit login' aria-label='Login'
                                        onClick={Login}>
                                    Login
                                </Button>
                            </div>
                            <div role='presentation'>
                                <br />
                                <p dir='ltr'>Or create an account by signing up here:</p>
                                <Button className='ml-remit-btn' title='ml remit sign up' id='signup' 
                                        variant='dark' size='sm' role='button' aria-description='ml remit sign up' aria-label='Sign up'
                                        onClick={SignUp}>
                                    Sign up
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className='sub-footer--wrapper'>
            <p className='sub-footer' dir='ltr'>A service provided by Servicio Uniteller, Inc.</p>
        </div>
    </div>
  )
}

export default LoginComponent;
