import React from 'react';
import '../styles/Faq.css';
import {Accordion} from 'react-bootstrap';
import Graph from "../assets/images/graph.png";

const FaqComponent = () => {
  return (
    <div className='faq' role='main' dir='ltr'>
        <div className="faq--wrapper colored-bg" id='main-content'>
            <section className='faq--heading' tabIndex="0">
                <div className='faq-heading'>
                    <div className='faq-heading--col'>
                        <div className='header'>
                            <div className='content'>
                                <h1>ML Remit Frequently Asked Questions</h1>
                                <hr />
                                <p>
                                Have a question about our services? To make your experience as fast, easy, 
                                and convenient as possible, we provide answers to some of our more frequently asked questions, including: 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='accordion--wrapper'>
                <div className='accordion--header'>
                    <div className="content">
                        <div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" tabIndex="0">
                                    <Accordion.Header title='expand'>I’m in the United States. How can I send money to the Philippines?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        With ML Remit, we make it easier and more convenient than ever to send money to the Philippines. 
                                        By downloading our ML Remit app, you have the opportunity to
                                        send money online from the USA to the Philippines directly from your device or smartphone.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are my payment options for sending money overseas?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        When you choose the ML Remit online option to send money from the U.S., 
                                        you have the choice of making payments by checking account, credit card, or debit card. 
                                        Additional fees may be charged by your card issuer when making a payment with credit card. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Where are your services available? </Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        With the ML Remit app, you may send money internationally from anywhere in the United States. This includes all 50 states. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How do I register?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        Please follow the steps below to start sending money :
                                        <ul>
                                            <li className="list-item">
                                                Select {" "}
                                                <a href="https://mlremit.com/uniteller" role="link" className='text-dark' title='link to ml remit unteller'>“Send Now”</a> 
                                                {" "} on the <a href="http://mlremit.com/" role="link" title='link to uniteller' style={{ textDecorationLine: "none" }}> MLRemit</a> homepage
                                            </li>
                                            <li className="list-item">
                                                Click “Sign Up To Send Money Now”
                                            </li>
                                            <li className="list-item">
                                                Enter your personal information and click the “Sign Up” button
                                            </li>
                                        </ul>
                                        After creating your account, you may proceed to entering your recipient’s information. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>I already have an account but I forgot my password. How do I reset it?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        <ul>
                                            <li className="list-item">
                                                Click “Forgot Password” link on the login page
                                            </li>
                                            <li className="list-item">
                                                Enter your email address in the box and click continue
                                            </li>
                                            <li className="list-item">
                                                Answer the security question you selected when you registered your account
                                            </li>
                                            <li className="list-item">
                                                Choose whether you want the identification code sent via mobile or email
                                            </li>
                                            <li className="list-item">
                                                Enter the identification code in the box
                                            </li>
                                            <li className="list-item">
                                                Create a new password
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>What is the charge for sending money internationally?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        <ul>
                                            <li className="list-item">
                                            When you choose ML Remit to send money, you’re choosing the cheapest way to send money to the Philippines from the USA.
                                            We charge a low <strong>$4.99</strong> convenience fee each time you send money. 
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>How long does it take to send money online from the U.S. to the Philippines?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        <ul>
                                            <li className="list-item">
                                                Debit and credit transactions are usually completed and available within just minutes. 
                                                Payments made via bank account ACH may take up to four business days.
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        <div className='graph'>
                            <h2>How much money can I send at a time?</h2>
                            <div className='py-3 graph-img'>
                                <img src={Graph} alt="sample transaction" title='sample transaction'/>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How can I send more?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            Please contact us to request a level increase if you want to enjoy higher sending limits and shorter available times of your transfers. 
                                            Call <strong>1.800.456.3492</strong> to check your eligibility. 
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What are the options for sending money to the Philippines?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            We offer cash pickup at over 3,000 M Lhuillier locations throughout the Philippines, many of which are open 24 hours. Your recipient will never have to pay fees.
                                        </li>
                                        <li className="list-item">
                                            *For your recipient to pick up the cash, they would need to bring the reference number and a valid photo ID.  
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>The spelling of my receiver’s name is incorrect. How do I correct the name?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            Send an email to <span className='text-danger'><strong>support@mlhuillierusa.com</strong></span> and please include the transaction number and correct information of your receiver. 
                                            You may also call <strong>1.800.456.3492</strong> to request changes to your beneficiary’s information.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>I've reached the maximum number of recipients on my account. How can I add a new recipient?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            Click the "Contacts" link on the upper right-hand corner of your Account page and select "Deactivate" for the contact that you want to delete. 
                                        </li>
                                    </ul> 
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Can I send a representative to pick up my money?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            We’re sorry but for security reasons we cannot release your money to a representative. 
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>How do I check the status of my transaction?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            The easiest way to check your transaction's status is to log in to your account and select <strong>Activity</strong>. 
                                            A status showing <strong>In Process</strong> indicates the transaction is still pending review. The money is ready for pick up once the status changes to <strong>Payable</strong>. 
                                            You may also call customer service at <strong>1.800.456.3492</strong> to check the status. Please be prepared to provide your transaction number to our representative. 
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>How do I cancel my transaction or get a refund?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <ul>
                                        <li className="list-item">
                                            Log in to your account, click <strong>Activity</strong>, then select the <strong>Cancel</strong> button for the transaction you want to cancel. 
                                            You may also call customer service at <strong>1.800.456.3492</strong> to request a cancellation or refund. 
                                            After canceling a transaction, it takes 1 to 2 business days for the money to be refunded back to your account. 
                                            Funds that your beneficiary has claimed can no longer be canceled or refunded.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>Where can I download your app?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <p>If you use an iPhone, go to the App Store icon and search <strong>“ml remit.”</strong></p>
                                    <p>If you use an Android phone, tap the Google Play Store icon and search <strong>“ml remit.”</strong></p>
                                    <p>Tap the <strong>ML Remit by M Lhuillier</strong> icon to start downloading the app. </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Where are you located?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <p>Our main office is located at 21700 Oxnard St. Suite 940, Woodland Hills, CA 91367. 
                                    M. Lhuillier currently has 18 convenient money transfer locations throughout the state of California and 2 in Nevada.</p>
                                    <p>ML Remit online remittance service is available in all 50 U.S. states.</p>
                                    <p><a href="https://www.mlremit.com/branch" role='link' title='find mlhuillier branch' className='text-dark'>Find an M Lhuillier branch in the US or the Philippines </a></p>
                                    <p className='py-3 text-center'>A service provided by Servicio Uniteller, Inc.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>What are accepted valid IDs at M. Lhuillier?</Accordion.Header>
                                <hr />
                                <Accordion.Body tabIndex="0">
                                    <p>The following are valid IDs that are acceptable in Kwarta Padala (sending and receiving money) transactions at any M. Lhuillier location:</p>
                                    <p className='pt-2'>1. The following GOVERNMENT -issued IDs –</p>
                                    <ul className='padded-ul'>
                                        <li className="list-item">
                                            Alien Certification of Registration (ACR) or Immigrant Certificate of Registration (ICR)
                                        </li>
                                        <li className="list-item">
                                            Driver’s License issued by the Philippine government (If issued by foreign countries or jurisdiction, 
                                            M. Lhuillier reserves the right to require additional valid identification document/card such as passport, 
                                            National ID or a local ID of the holder/customer or the presentation of documents that will further assure the represented/asserted identity of customer).
                                        </li>
                                        <li className="list-item">
                                            Government Service Insurance System (GSIS) Electronic Card ID or (E-Card PLUS).
                                        </li>
                                        <li className="list-item">
                                            National Bureau of Investigation (NBI) Clearance with signature and photo M.Lhuillier may not consider 
                                            “Clearance Renewal Card” as valid ID because of the purpose upon which it was issued. 
                                            However it is a good supporting document of identification.
                                        </li>
                                        <li className="list-item">
                                            Overseas Filipino Worker (OFW) ID or Department Of Labor and Employment (DOLE) Visa card.
                                        </li>
                                        <li className="list-item">
                                            Overseas Workers Welfare Administration (OWWA) ID (issued by DOLE)
                                        </li>
                                        <li className="list-item">
                                            Passport issued by Philippine and foreign governments (For foreign passports, there are instances when 
                                            M. Lhuillier may require additional valid identification or presentation of documents that will further 
                                            assure the represented/asserted identity of customer due to issues of familiarity and authentication of the foreign-issued document. 
                                            Examples of these circumstances are: i. first time sends or receives of large amounts; ii. 
                                            High frequency transactions; and iii. Transactions involving multiple counter-parties)
                                        </li>
                                        <li className="list-item">
                                            Police Clearance Certificate (with signature of holder/applicant and digitized photo)
                                        </li>
                                        <li className="list-item">
                                            Professional Regulation Commission (PRC) ID
                                        </li>
                                        <li className="list-item">
                                            Seaman’s Book and Seafarer’s Registration Certificate
                                        </li>
                                        <li className="list-item">
                                            Senior Citizen’s ID
                                        </li>
                                        <li className="list-item">
                                            Social Security System (SSS) ID with photo
                                        </li>
                                        <li className="list-item">
                                            Voter’s or New Voter’s ID
                                        </li>
                                        <li className="list-item">
                                            Other Government Office and GOCC IDs (example but not limited to: 
                                            Armed Forces of the Philippines ID, Fire Arm License issued by the AFP, HDMF/PagIBIG, Postal, PhilHealth, DSWD, NCWDP, etc)
                                        </li>
                                    </ul>
                                    2. Other acceptable IDs include:
                                    <ul className='padded-ul'>
                                        <li className="list-item">
                                            Integrated Bar of the Philippines Membership Card or ID
                                        </li>
                                    </ul>
                                    3. IDs stated below are acceptable only at M.Lhuillier branches that are located within the locality (city or municipality) where these identification documents or cards are issued:
                                    <ul className='padded-ul'>
                                        <li className="list-item">
                                            Barangay Identification
                                        </li>
                                    </ul>
                                    4. Employment ID, which are issued by private institutions or entities that are REGISTERED with, SUPERVISED or REGULATED BY the
                                    <ul className='padded-ul'>
                                        <li className="list-item">
                                            Securities and Exchange Commission [SEC];
                                        </li>
                                        <li className="list-item">
                                            BangkoSentral ng Pilipinas [BSP]; or
                                        </li>
                                        <li className="list-item">
                                            Insurance Commission [IC]
                                        </li>
                                    </ul>
                                    5. School ID (for MINOR receivers, i.e., those below 18 years of age but of such school age enough to appreciate/understand the value and use of money)
                                    <p>
                                        First time customers using school IDs are asked to present, together with the school ID, current study load or study load of the immediately preceding 
                                        school year as supporting document. Other qualified supporting documents are –
                                    </p>
                                    <ul className='padded-ul'>
                                        <li className="list-item">
                                            Enrollment Card; or
                                        </li>
                                        <li className="list-item">
                                            Official Receipts (O.R.) issued by the school; or
                                        </li>
                                        <li className="list-item">
                                            Assessment Slips.
                                        </li>
                                    </ul>
                                    6. The documents below are good supporting documents :
                                    <ul className='padded-ul'>
                                        <li className="list-item">
                                            Birth/Marriage Certificates and Certificate of Singleness;
                                        </li>
                                        <li className="list-item">
                                            Billing and Bank Statements (such as those issued by Electric companies, Water service companies, Credit card issuers, Telecom companies, etc.)
                                        </li>
                                        <li className="list-item">
                                            Credit Cards
                                        </li>
                                        <li className="list-item">
                                            Those Identification Cards that M. Lhuillier may not consider as valid ID for the transaction.
                                        </li>
                                    </ul>
                                    <p>
                                        <em>
                                            Kind Note to customers (with emphasis on ID Types No. I.15, III, IV and V)
                                        </em>
                                    </p>

                                    <p>
                                        M.Lhuillier reserves its right or discretion to verify authenticity of any of these identification documents (IDs) directly from its issuing authorities, 
                                        where the attending branch employee finds reason or basis to do so because of any, some or all of the circumstance(s) present on:
                                    </p>
                                    <p>
                                        <strong>1.</strong> the ID itself,         
                                    </p>
                                    <p>
                                        <strong>2.</strong> the nature of customer’s transaction including details supplied for mandatory/required information,         
                                    </p>
                                    <p>
                                        <strong>3.</strong> customer type/classification,         
                                    </p>
                                    <p>
                                        <strong>4.</strong> location of the branch; and the lack of other independent authenticating/corroborating identification documents.        
                                    </p>
                                    <p>The company requests the customers’ kind indulgence in case the procedure may entail delay or if failure to verify under this and alternative means, 
                                        may result to refusal in processing your (the customer’s) transaction(s).
                                    </p>
                                    <p>
                                        Whenever it deems necessary, M. Lhuillier may accept other IDs not in the list provided that it shall not be the sole means of identification. 
                                        Likewise, in case any of the IDs or ID types enumerated herein contains ID photos that are not clear or not current or don’t have any picture of the customer, 
                                        M.Lhuillier may exercise its discretion to use its own technology to capture the photo or profile of said customers and/or 
                                        their authorized representatives where applicable.(§X806.2.c. (4.) to (7.), BSP Circular No. 706 Series of 2011)
                                    </p>
                                    <p>
                                        On the other hand, MLhuillier reserves the right to refuse any of the IDs listed here on reasonable ground.  
                                    </p>
                                    <p>
                                        M. Lhuillier does all these in order to protect the interests of its customers and to properly execute or carry out the senders’ 
                                        instructions of delivering the money only to the rightful receiver/s, while ensuring that the financially and socially disadvantaged are not denied access 
                                        to financial services; all upon the commitment to serve you better and to comply with the relevant laws and rules that govern its pawnshops/quick cash loan, 
                                        money remittance and money changing services.
                                    </p>
                                    <div>
                                        <h6><strong>REMINDERS!</strong></h6>
                                        <span>
                                            <strong>We wish to remind our dear customers that by valid ID it generally means:</strong>
                                        </span>
                                        <strong>
                                            <ul>
                                                <li className="list-item">
                                                    Photo-bearing;
                                                </li>
                                                <li className="list-item">
                                                    Has the Name and Signature of the holder/owner thereof;
                                                </li>
                                                <li className="list-item">
                                                    Not expired at the time of use/transaction
                                                </li>
                                                <li className="list-item">
                                                    Issued by Official Authority
                                                </li>
                                            </ul>
                                        </strong>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className='graph pt-4' tabIndex="0">
                            <div style={{ fontSize:"25px", margin:"5px 0 10px 0" }}>For other questions or concerns, please contact us directly:</div>
                            <p>Learn more today by calling us, toll free, at 1.877.688.4588.</p>
                            <p>Email: <a href="#" title='mlhuillier usa support email' style={{ textDecorationLine: "none", color:"blue" }}>support@mlhuillierusa.com</a></p>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
       </div>
       <div className="faq--wrapper">
            <section className='accordion--wrapper' tabIndex="0">
                <div className='accordion--header'>
                    <div className="content">
                        <div>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>Is it safe to send money to the Philippines using your online service?</Accordion.Header>
                                    <hr />
                                    <Accordion.Body tabIndex="0">
                                        <ul>
                                            <li className="list-item">
                                                We take pride in offering the best and safest way to send money from the U.S. to the Philippines. 
                                                You can rest easy knowing you’re using the most secure and most convenient method available for sending money internationally. 
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
       </div>
    </div>
  );
}

export default FaqComponent;