import React from 'react'
import {TermsComponent} from '../components'

export const Terms_Condition = () => {

  document.title = "M Lhuillier Financial Services, Inc. | ML USA Terms and Conditions"

  return (
    <TermsComponent/>
  )
}
