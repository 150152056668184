import React from 'react';
import '../styles/TermsCondition.css';

const TermsComponent = () => {
  return (
    <div className='terms-condition container' role='main' dir='ltr' id='main-content'>
        <section tabIndex="0">
            <div className="content">
                <div className="terms-condition--heading">
                    <h1>Terms and Conditions for Pawn transactions in West Covina, Concord and Sacramento branches</h1>
                </div>
                <p>
                    By providing us with your mobile number and opting in, you give M. Lhuillier, LLC permission to 
                    send you account-related text messages, like payment reminders and notifications, as well as 
                    promotional messages, like special offers and discounts, in conjunction with the services you 
                    have requested. 
                </p>
                <div>
                    <ul className="list">
                        <li className="list-item">
                            Number of messages will vary by account. 
                        </li>
                        <li className="list-item">
                            By providing us with your mobile number and opting in, 
                            you agree you have ownership rights or permission to use the number given to us. 
                        </li>
                        <li className="list-item">
                            Message and data rates may apply.  
                        </li>
                        <li className="list-item">
                            To opt out, text STOP to any text message we send you. An opt-out confirmation message 
                            will be sent back to you.  
                        </li>
                        <li className="list-item">
                            To request support, text HELP to any text message we send you or email us at 
                            <a href="mailto:support@mlhuillierusa.com" target="_blank" role='link' title='ml usa support' aria-label='ml usa support' style={{  color:"#024FC0"  }}>
                                {" "}support@mlhuillierusa.com.
                            </a>
                        </li>
                        <li className="list-item">
                            If your handset does not support MMS, any MMS messages sent may be delivered as SMS messages. 
                        </li>
                        <li className="list-item">
                            Wireless carriers are not liable for undelivered or delayed messages. 
                        </li>
                    </ul>
                    <p>
                        No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. 
                        All the above categories exclude text messaging originator opt-in data and consent; this information 
                        will not be shared with any third parties. 
                    </p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default TermsComponent;