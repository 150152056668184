import React from 'react'
import {BlogComponent} from '../components'

const Blog = () => {

  document.title = "M Lhuillier Financial Services, Inc. | ML USA Blog"

  return (
    <BlogComponent/>
  )
}

export default Blog;