import React from 'react'
import {HomeComponent} from '../components'

export const Home = () => {

  document.title = "M Lhuillier Financial Services, Inc. | Home"

  return (
    <HomeComponent/>
  )
}
