import React from 'react'
import { FooterComponent, NavbarComponent } from "../components";
import { Outlet } from 'react-router-dom';
import '../App.css';

const MainOutlet = () => {
  return (
    <div className='main--wrapper'>
        <a className="skip-link" href='#main-content' aria-label='skip to content'>Skip to content</a> 
        <NavbarComponent id="header"/>
        <Outlet id="main"/>
        <div className='footer-space'> </div>
        <FooterComponent id="footer"/>
    </div>
  )
}

export default MainOutlet;
