import React from 'react'
import {BranchesComponent} from '../components'

const Branches = () => {

  document.title = "M Lhuillier Financial Services, Inc. | Branches"

  return (
    <BranchesComponent/>
  )
}

export default Branches