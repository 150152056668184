const KwartaPadalaServices = [
    "Arroyo",
    "Carson",
    "Cerritos",
    "Chula Vista",
    "Concord",
    "Daly City",
    "Eagle Rock",
    "Hayward",
    "Henderson",
    "Long Beach",
    "National City",
    "North Hills",
    "Oxnard",
    "Panorama City",
    "Sacramento",
    "San Jose",
    "Santa Clarita",
    "Union City",
    "Vallejo",
    "Vermont",
    "West Covina"
]

const UsaBranches = [
    {
        branchname:"ML ARROYO",
        address: "7230 ARROYO CROSSING PARKWAY LAS VEGAS, NV 89113",
        number: "(702) 541-8460"
    },
    {
        branchname:"ML CARSON",
        address: "21822 S. MAIN ST. CARSON CA. 90745",
        number: "(310) 952-9525"
    },
    {
        branchname:"ML CERRITOS",
        address: "17300 NORWALK BLVD, CERRITOS, CA 90703",
        number: "(562) 219-7119"
    },
    {
        branchname:"ML CHULA VISTA",
        address: "285 E. ORANGE AVE., UNIT P A1, CHULA VISTA",
        number: "(619) 498-0441"
    },
    {
        branchname:"ML CONCORD",
        address: "2030 DIAMOND BLVD., SUITE Q-80, CONCORD, CA 94520",
        number: "(925) 609-7788"
    },
    {
        branchname:"ML DALY CITY",
        address: "3565 CALLAN BLVD, SOUTH FRANCISCO, CA 94080",
        number: "(650) 634-8454"
    },
    {
        branchname:"ML EAGLE ROCK",
        address: "2700 COLORADO BLVD. #9004, LOS ANGELES, CA 90041",
        number: "(323) 255-1525"
    },
    {
        branchname:"ML HAYWARD",
        address: "24536 HESPERIAN BLVD, UNIT 102, HAYWARD, CA 94545",
        number: "(510) 274-5914"
    },
    {
        branchname:"ML HENDERSON",
        address: "10405 S. EASTERN AVE., HENDERSON, NV 89052",
        number: "702-538-8679"
    },
    {
        branchname:"ML LONG BEACH",
        address: "2520 SANTA FE AVE. LONG BEACH CA. 90810",
        number: "562-989-2000"
    },
    {
        branchname:"ML NATIONAL CITY",
        address: "1430 E. PLAZA BLVD, UNIT # E17, NATIONAL CITY, CA 91950",
        number: "(619)399-5889"
    },
    {
        branchname:"ML NORTH HILLS",
        address: "16130 NORDHOFF STREET, NORTH HILLS, CA 91343",
        number: "(818) 891-8881"
    },
    {
        branchname:"ML OXNARD",
        address: "4833 S. ROSE AVE., OXNARD, CA 93033",
        number: "(805) 986-5000"
    },
    {
        branchname:"ML PANORAMA",
        address: "8257 WOODMAN AVE, PANORAMA CITY, CA 91402",
        number: "(818) 892-6200"
    },
    {
        branchname:"ML SACRAMENTO",
        address: "6051 MACK RD. #A SACRAMENTO, CA 95823",
        number: "(916) 422-2888"
    },
    {
        branchname:"ML SAN JOSE",
        address: "311 N CAPITOL AVENUE SUITE # 50, SAN JOSE, CA 95133",
        number: "(408) 929-5888"
    },
    {
        branchname:"ML SANTA CLARITA",
        address: "19387 SOLEDAD  CANYON Rd., SANTA CLARITA, CA. 91351",
        number: "(661) 347-9030"
    },
    {
        branchname:"ML UNION CITY",
        address: "4122 DYER ST., KIOSK A, UNION CITY, CA 94587",
        number: "(510) 441-1564"
    },
    {
        branchname:"ML VALLEJO",
        address: "3495 SONOMA BLVD., VALLEJO, CA 94590",
        number: "(707) 648-1000"
    },
    {
        branchname:"ML VERMONT",
        address: "628 N. VERMONT AVE. UNIT 1, LOS ANGELES, CA 90004",
        number: "323-660-0808"
    },
    {
        branchname:"ML WEST COVINA",
        address: "1559-X, EAST AMAR ROAD, WEST COVINA, CA 91792",
        number: "(626) 965-5511"
    },
    {
        branchname:"ML WOODLAND HILLS",
        address: "21700 OXNARD ST., STE 940, WOODLAND HILLS, CA 91367",
        number: "(818) 888-6100"
    },
]

export{
    KwartaPadalaServices,
    UsaBranches
}