import React, {useEffect, useState} from 'react';
import '../styles/Home.css';
import {Button} from "react-bootstrap";
import MapPhilippines from '../assets/images/image.png';
import MLRemitApp from '../assets/images/ml-remit-app.png';
import AppStore from '../assets/images/app-store.png';
import GooglePlay from '../assets/images/google-play.png';
import { useNavigate } from 'react-router-dom';

const HomeComponent = () => {

    const [transformStyle, setTransformVal] = useState({height: '100vh', transform: `translate3d(0px, 0px, 0px)`});

    const navigate = useNavigate();

    useEffect(() => {

    })

    window.onscroll = function(){
        var scrollTop = document.scrollingElement.scrollTop;

        // console.log(scrollTop);
        // console.log('height', scrollTop / 5);

        let h = 50

        let transform = {
            transform: `translate3d(0px, ${scrollTop === 0 ? scrollTop : scrollTop - 50}px, 0px)`,
            height: `${scrollTop === 0 ? 100 : h + 10}vh`
        }

        setTransformVal(transform);
    }

    const SendNow = () => {
        navigate("/product-services/login-ml-remit",
            { replace: true }
        )
    }

  return (
    <div id="home" role="main" dir='ltr' tabIndex="-1" aria-hidden="false">
        <div className="parallax" id='parallax header' aria-label='slider'>
        {/* <div className="parallax" id='parallax' style={transformStyle}> */}
            <div className='parallax-img' role='img' aria-label='page banner valentine special'>
                <Button aria-label='Scoll down' disabled="false~" tabIndex="0" variant='light' style={{ background: "transparent", border:"none", fill:"black" }}>
                    <img role='img'
                        alt='Scroll down'
                        style={{ maxWidth:"100%", height:"auto" }}
                        title='Scroll Down'
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVR4nO3UXQrDIBAEYE/QJrRHyJUEd3bE2wV6ktJbFaENKbXUv7w5j7rOh5BozMjIoVHVk6pKCGHq0RdCmEREY++2SNKp6oPkCmBuAQDMsefV57YNa+15t1EN4RNYY+/XAID3wE1ErqUAdue995eswVwIuUAthFKgFEItkAuhFfgHoRfwCyK5dAVS3z+Ae68fNwnhiBuk3iSSrtcbNzJimvMExOah9xXqaCIAAAAASUVORK5CYII=">
                    </img>
                </Button>
            </div>
        </div>
        <div className='home-content' id='main-content'>
            <section className='content-1'>
                <h1 style={{ color:"#212529" }}>Quickly and safely send money to the Philippines using a debit card, credit card, <br /> or bank account.</h1>
            </section>
            <div className='content-2'>
               <div tabIndex="-1">
                    <Button onClick={SendNow} title='send money' id='send-now' variant='dark' size='sm' aria-label='Send now'>
                        Send Now
                    </Button>
               </div>
            </div>
            <section className='content-3'>
                <div className='context' tabIndex="-1">
                    <h2>Quick and easy pickup service</h2>
                    <p style={{ color:"rgba(33,33,33,1)" }}>With over <strong>3,000</strong> locations throughout the Philippines, many of which are open <br />24 hours, there’s sure to be one that’s 
                        convenient for your recipient to visit. The money typically arrives within minutes, 
                        and your recipient will never have to pay fees.
                    </p>
                </div>
                <div className='map'>
                    <img 
                    src={MapPhilippines} 
                    alt="Map of the Philippines" 
                    role='img' 
                    aria-label="Map of the Philippines" 
                    title='Map of the philippines'
                    style={{ maxWidth:"100%", height:"auto" }}/>
                </div>
            </section>
            <div className='content-4'>
                <div tabIndex="-1">
                    <h2>Send money on the go</h2>
                    <p>Download the ML Remit app to send money, and check the status of your money transfer.</p>
                    <p>A service provided by Servicio Uniteller, Inc.</p>
                </div>
            </div>
            <section className="content-5">
                <div tabIndex="-1">
                    <img src={MLRemitApp} 
                    alt="ML remit mobile app" role='img' 
                    title='ML remit mobile app' style={{ maxWidth:"100%", height:"auto" }}/>
                </div>
            </section>
            <div className='content-6'>
                <div className='wrapper' tabIndex="-1">
                    <div className='link-wrapper'>
                        <div>
                            <a href="https://apps.apple.com/us/app/ml-remit-money-transfer/id1289510718" 
                                aria-label='download ml remit app on appstore'
                                title='download ml remit app on appstore'>
                                    <img src={AppStore} 
                                    alt="ML remit app on app store" 
                                    title='ML remit app on app store'
                                    role='img' style={{ maxWidth:"100%", height:"auto" }}/>
                            </a>
                        </div>
                    </div>
                    <div className="link-wrapper">
                        <div>
                            <a href="https://play.google.com/store/apps/details?id=com.mlhuillier.paynearme&pcampaignid=web_share"  
                            aria-label='download ml remit app on google play' 
                            title='download ml remit app on google play'>
                                    <img src={GooglePlay} 
                                    alt="ML remit app on app store"
                                    title='ML remit app on app store'
                                    role='img' style={{ maxWidth:"100%", height:"auto" }}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HomeComponent;