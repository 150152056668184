import React from 'react';
import '../styles/Branches.css';
import {Accordion} from 'react-bootstrap';
import {UsaBranches} from '../utils/Data';

const UsaBranchesComponents = () => {

   const CollapseItem = () => {
    return UsaBranches?.map((item, key) => {
        return (
            <Accordion.Item key={key} eventKey={key}>
                <Accordion.Button role='button' title='Expand'>
                    <div>{item.branchname}</div>
                </Accordion.Button>
                <hr />
                <Accordion.Body tabIndex="0">
                    <p className='address-label'>ADDRESS</p>
                    <div className="address">
                        <p>
                            {item.address}
                        </p>
                        <p>
                            {item.number}
                        </p>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        )
    })
   } 
  return (
    <div id='content' className='usa-branches main' role='main' dir='ltr'>
        <section className="usa--branches heading" tabIndex="0" id='main-content'>
            <div className='usa-branches--heading'>
                <div className='heading'>
                    <h1>
                        U.S. BRANCHES
                    </h1>
                </div>
            </div>
        </section>
        <section className="usa--branches content">
            <div className='usa-branches--heading'>
                <div className='heading-accordion'>
                    <Accordion defaultActiveKey={"0"}>
                        <CollapseItem/>
                    </Accordion>
                </div>
            </div>
        </section>
    </div>
  )
}

export default UsaBranchesComponents;