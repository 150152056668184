import React from 'react';
import '../styles/Branches.css';
import UsaFlag from '../assets/images/usa-flag.jpg';
import PhFlag from '../assets/images/Flag-Philippines.jpg';

const BranchesComponent = () => {
  return (
    <div className='branches--wrapper' role='main' dir='ltr'>
        <h1 tabindex="-1" hidden="true">Branches</h1>
        <section className='branches-heading' tabIndex="0" id='main-content'>
            <div>
                <div>
                    <h1>
                        <span>BRANCHES</span>
                    </h1>
                </div>
            </div>
        </section>
        <div className='branches' tabIndex="0">
            <div className="branches-col">
                <div className='first-col'></div>
                <div className='usa--wrapper'>
                    <div className='usa'>
                        <div>
                            <img src={UsaFlag} alt="American flag" title='American flag' role='img'/>
                        </div>
                        <p><a href="/branches/usa-branches" role='link' title='usa branches' aria-label='usa branches'>USA</a></p>
                    </div>
                </div>
                <div className='middle-col'></div>
                <div className='philippines--wrapper'>
                    <div className='philippines'>
                        <div>
                            <img src={PhFlag} alt="Philippine flag" title='Philippine flag' role='img'/>
                        </div>
                        <p><a href="https://mlhuillier.com/branches/" role='link' title='philippine branches' aria-label='philippine branches'>Philippines</a></p>
                    </div>
                </div>
                <div className='last-col'></div>
            </div>
        </div>
    </div>
  )
}

export default BranchesComponent;