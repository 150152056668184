import { createBrowserRouter, Navigate } from "react-router-dom";
import {Home} from "../views/Home";
import {Products_Services} from "../views/ProductsServices";
import {Terms_Condition} from "../views/TermsCondition";
import {Faq} from "../views/Faq";
import MainOutlet from "../views/MainOutlet";
import Login from "../views/Login";
import MLUsaServices from "../views/MLUsaServices";
import Branches from "../views/Branches";
import Blog from "../views/Blog";
import Contact from "../views/Contact";
import UsaBranches from "../views/UsaBranches";
import { ConsumerDisclosureComponent } from "../components";



const routes = createBrowserRouter([
    {
        path: '/',
        element: <MainOutlet/>,
        children: [
            { path: "/", element: <Navigate to="/home" replace /> },
            {
                path: '/home',
                element: <Home/>
            },
            {
                path: '/products-and-services',
                element: <Products_Services/>
            },
            {
                path: '/terms-and-conditions',
                element: <Terms_Condition/>
            },
            {
                path: '/faq',
                element: <Faq/>
            },
            {
                path: '/branches',
                element: <Branches/>
            },
            {
                path: '/contact',
                element: <Contact/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/ml-usa-services',
                element: <MLUsaServices/>
            },
            {
                path: '/branches/usa-branches',
                element: <UsaBranches/>
            },
            {
                path: '/product-services/login-ml-remit',
                element: <Login/>
            }
        ]
    },
    {
        path: '/california-consumer-disclosure',
        element: <ConsumerDisclosureComponent/>

    }
])

export default routes;