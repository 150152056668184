import React from "react";
import MLRemitLogo from '../assets/images/logo_en.png';
import DisclosureIcon from '../assets/images/disclosure.png';

import '../styles/Disclosure.css';
import FooterComponent from './Layout/FooterComponent';

const ConsumerDisclosureComponent = () => {
    return (
        <div className='disclosure' role='main' dir='ltr'>
            {/* <h1 tabindex="-1">Contact</h1> */}
            <header className="disclosure--header">
                <div className="container pt-3 pb-3">
                    <img src={MLRemitLogo} alt="ML Remit Logo" />
                </div>
            </header>
            <div className="content" id='main-content'>
                <section tabIndex="0">
                    <div>
                        <div className="disclosure--heading">
                            <img src={DisclosureIcon} alt="disclosure-icon" />
                            <h1>California Consumer Disclosure</h1>
                        </div>
                    </div>
                </section>
                <div className="disclosure--sections container">
                    <section className="right-to-refund">
                        <div className="right-to-refund--english">
                            <h2>
                                <span>RIGHT TO REFUND:</span>
                            </h2>
                            <br />
                            <p>
                                You, the customer, are entitled to a refund of the money to be transmitted as
                                the result of this agreement if M. LHUILLIER FINANCIAL SERVICES, INC. does not forward the
                                money received from you within 10 days of the date of its receipt, or does not give instructions
                                committing an equivalent amount of money to the person designated by you within 10 days of the
                                date of the receipt of the funds from you unless otherwise instructed by you. If your instructions as to
                                when the moneys shall be forwarded or transmitted are not complied with and the money has not yet
                                been forwarded or transmitted you have a right to a refund of your money. If you want a refund, you
                                must mail or deliver your written request to M. LHUILLIER FINANCIAL SERVICES, INC. at 21700
                                Oxnard St., Suite 940, Woodland Hills, CA 91367. If you do not receive your refund, you may be
                                entitled to your money back plus a penalty of up to $1,000 and attorney’s fees pursuant to Section
                                2102 of the California Financial Code.
                            </p>
                        </div>
                        <br />
                        <div className="right-to-refund--filipino">
                            <h2>
                                <span>KARAPATAN SA PAG-REFUND:</span>
                            </h2>
                            <br />
                            <p>
                                Ikaw, bilang kustomer ay may karapatan na maibalik ang iyong
                                perang ipadadala bunga ng kasunduan ito kung ang M. LHUILLIER FINANCIAL SERVICES, INC ay
                                hindi ipinadala ang perang natanggap mula sa iyo sa loob ng 10 araw simula sa petsa ng resibong ito
                                o hindi nakapagbigay ng mga tagubilin na nangangako ng katumbas na halaga ng pera sa taong
                                iyong itinalaga sa loob ng 10 araw simula sa petsa ng pagtanggap ng pondo mula sa iyo, maliban na
                                lamang kung mayroon kang ibang tagubilin. Kung ang iyong mga tagubilin ay kung kailan dapat
                                ipadadala ang pera o kung ang paghahatid ay hindi pa naisakatuparan at ang pera ay hindi pa
                                naipadadala o naihahatid, may karapatan kang maibalik ang iyong pera. Kung nais mong maibalik ito,
                                kailangan mong magpadala ng sulat na kahilingan sa M. LHUILLIER FINANCIAL SERVICES, INC. at
                                21700 Oxnard St., Suite 940, Woodland Hills, CA 91367. Kung hindi mo natanggap pabalik ang iyong
                                pera, maaari mong makuha pabalik ang iyong pera dagdag ang isang multa na maaaring umabot sa
                                $1,000 at kabayaran para sa abogado na sangayon sa Seksyon na 2102 ng Batas para
                                Pampinansyal ng California (California Financial Code).
                            </p>
                        </div>
                    </section>
                    <br />
                    <section className="consumer-notice--section">
                        <div className="consumer-notice-content">
                            <h2>
                                <span>Consumer Notice</span>
                            </h2>
                            <br />
                            <p>
                                If you have complaints with respect to any aspect of the money transmission activities conducted at
                                this location, you may contact the California Department of Financial Protection and Innovation at its
                                toll-free telephone number, 1-866-275-2677, by email at consumer.services@dfpi.ca.gov, or by mail
                                at the Department of Financial Protection and Innovation, Consumer Services, 2101 Arena
                                Boulevard, Sacramento, CA 95834.
                            </p>
                        </div>
                    </section>
                    <br />
                    <section className="paunawa--wrapper">
                        <div className="paunawa-content">
                            <h2>
                                <span>Paunawa Sa Mga Mamimili</span>
                            </h2>
                            <br />
                            <p>
                                Kung mayron kayong reklamo ukol sa anumang aspeto ng mga gawain ng pagpapadala ng pera na
                                isinasagawa sa lokasyon na ito, maari kayong makipag ugnayan sa California Department of
                                Financial Protection and Innovation sa toll-free telephone number, 1-866-275-2677, by email sa
                                consumer.services@dfpi.ca.gov, o maari rin kayong sumulat sa Department of Financial Protection
                                and Innovation, Consumer Services, 2101 Arena Boulevard, Sacramento, CA 95834.
                            </p>
                        </div>
                    </section>
                </div>
            </div>
            <br />
            <br />
            <br />
            {/* <MLRemitFooterComponent/> */}
            <FooterComponent/>
        </div>
    )
}

export default ConsumerDisclosureComponent;