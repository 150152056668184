import React from 'react'
import FaqComponent from '../components/FaqComponent'

export const Faq = () => {

  document.title = "M Lhuillier Financial Services, Inc. | ML USA FAQ"

  return (
    <FaqComponent/>
  )
}
