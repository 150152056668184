import React from 'react';
import '../styles/Contact.css';

const ContactComponent = () => {
  return (
    <div className='contact container' role='main' dir='ltr'>
        {/* <h1 tabindex="-1">Contact</h1> */}
       <section tabIndex="0" id='main-content'>
        <div className="content">
            <div className="contact--heading">
                <h1>Contact Us</h1>
            </div>
            <br />
            <div>
                <div className="contact--usa">
                    <p><strong>United States</strong></p>
                    <p>Toll-free: 1-(877) 688-4588</p>
                    <p>Email: <a href="mailto:support@mlhuillierusa.com" target="_blank" role='link' title='ml usa support' aria-label='ml usa support' style={{ textDecorationLine: "none", color:"#024FC0" }}>support@mlhuillierusa.com</a></p>
                    <p>ML Corporate Office</p>
                    <p>21700 Oxnard St., Ste 940</p>
                    <p>Woodland Hills, CA 91367</p>
                </div>
                <br />
                <br />
                <div className="contact--ph">
                    <p><strong>Philippines</strong></p>
                    <p>Mobile: +63-947-999-0337, +63-947-999-2721, +63-917-871-2973, +63-947-999-0522, +63-947-999-2472</p>
                    <p>Email: <a href="mailto:customercare@mlhuillier.com" target="_blank" className='text-dark'>customercare@mlhuillier.com</a></p>
                </div>
            </div>
        </div>
       </section>
    </div>
  )
}

export default ContactComponent;