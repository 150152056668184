import React from 'react'
import {MLUsServicesComponent} from '../components';

const MLUsaServices = () => {

  document.title = "M Lhuillier Financial Services, Inc. | ML USA Services"

  return (
    <MLUsServicesComponent/>
  )
}

export default MLUsaServices;