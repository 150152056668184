import React from 'react';
import UsaBranchesComponents from '../components/UsaBranchesComponents';

const UsaBranches = () => {

  document.title = "M Lhuillier Financial Services, Inc. | ML USA Branches"

  return (
    <UsaBranchesComponents/>
  )
}

export default UsaBranches;