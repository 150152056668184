import React from 'react';
import '../../styles/Footer.css';
import mlLogo from '../../assets/images/footer-logo.png';

const FooterComponent = () => {
    // const year = new Date().getFullYear();

    return (
        <footer className='footer' aria-label="footer" id='footer' tabIndex="0">
            <div className='footer--wrapper' id='content'>
                <div className="logo">
                    <img src={mlLogo} alt="Mlhuillier logo" role='img' title='Mlhuillier logo' style={{ maxWidth: "100%", height: "auto" }} />
                </div>
                <div className='contact'>
                    <img
                        role="img"
                        alt='Landline phone'
                        title='Landline phone'
                        style={{ maxWidth: "100%", height: "auto" }}
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABWElEQVR4nMWUO0tDMRiGuzg4dlCLoLiWQo8UWlDo4mXspvgD9C94WXTs5CC24KJCF0UX0YoUF4cqml/1yAcnUEIuJ6dHDLwQkveSfLmUSv/VgFlgGzgBboER8JlilI4dA1vCjTGuAEfAB6AyQriHovUZzwAHwDjCWBkQ7b54mebzwI1DNAD2gBqwkKKWjg0cmmtgTpsvAk+eVXU8u+54dM/AkpDuA9tueAIaAe2dkF49hG+g7AkoAz8e/VBITcfBythOhlu369E3NakNfE1MPgCXGW+UcHrA48SYeLXNlWwAb8A5cJrjip4BF8A7sOnb8rKxG5URcl4roZJKQNcQ1oEk0NfohsyrlluRAKuBvoZoq76A3hTfhEpx5TJvOQT1iBJptGwBfQc5iSiRRt8WMM0vqsy3YQtQBZZIxQQkOUoUFaDywBYwLDDgxRawLhNFmANr1rfwF+0Xty7Vj7sBGEYAAAAASUVORK5CYII=" />
                    <div>
                        <p>Toll Free: (877) 688-4588</p>
                        <p>Email: support@mlhuillierusa.com</p>
                    </div>
                </div>
                <div className='reserved'>
                    <p>Copyright © M. Lhuillier Financial Services, Inc. All Rights Reserved.</p>
                    <div className="footer--links">
                        <p>
                            <a href="https://send.uniteller.com/jsps/AggrementTermsAndConditionSignup.action?document=Privacy_Polic
y&amp;wlpCode=MLHUILLIER&amp;lang=en&amp;tokenSecurity=bc25bfc056b4c906d76e2007c07f8d0d2e8b4490
5e6760d21073ef635f43f763b99e7ca0de7f83e1206e79269152e92b02d794bb2801fe4007e3f9551542
fbdf&amp;uniseccodenc=05b63905096f46c69b92e14d36055946e07ba4cc3b70b5c150a04e08fd2332d071
4a58bc28375466e98e3d8d0b83f9c7b6c3a3a33a2b6ae00de55edf2eb69b50" title='privacy policy' id='privacy-policy' role='button'>
                                Privacy Policy
                            </a>
                        </p>
                        <p>|</p>
                        <p>
                            <a href="/california-consumer-disclosure" title='california-consumer-disclosure' id='california-consumer-disclosure' role='button'>
                                California Consumer Disclosure
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent