import React from 'react';
import {LoginComponent} from '../components';

const Login = () => {

    document.title = "Login | ML Remit"
    
  return (
    <LoginComponent/>
  )
}

export default Login