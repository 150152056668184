import React from 'react'
import {ContactComponent} from '../components';

const Contact = () => {

  document.title = "M Lhuillier Financial Services, Inc. | ML USA Contact Us"

  return (
    <ContactComponent/>
  )
}

export default Contact;