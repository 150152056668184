import React from 'react';
import '../styles/Blog.css';
import {Accordion} from 'react-bootstrap';

const BlogComponent = () => {
  return (
    <div className="blog" role='main' dir='ltr' tabIndex="-1">
      <div className='blog--wrapper-1'>
        {/* <h1 className='blog-col-1' tabIndex="-1" style={{ textAlign: 'center' }}>Blog</h1> */}
        <div className='blog-col-1' tabIndex="-1"></div>
      </div>
      <div className='blog--wrapper-2' id='main-content'>
        <section className='accordion--wrapper'>
          <div className="content">
          <Accordion defaultActiveKey="0">
            <Accordion.Item tabIndex="-1" eventKey="0">
              <div className='hidden--wrapper' tabIndex="-1"></div>
              <Accordion.Header>
                3 Tips for sending money to the Philippines using ML Remit.
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir="ltr">
                  If you have family, friends or business associates in the Philippines, 
                  it may at some point become necessary to send them money. You could mail a check, 
                  but why? Sending money to the Philippines is quick and easy if you use ML Remit.
                </p>
                <p dir="ltr">
                  We give you the tools to send money to the Philippines via bank account, debit and credit cards. 
                  Additional fees may be charged by your card issuer when paying by debit or credit card.
                </p>
                <p dir="ltr">
                  Here are some tips to make the whole process run smoothly:
                </p>
                <ul className="list bold">
                  <li dir='ltr' className="list-item">
                    <p dir="ltr" role="presentation">
                      <span><strong>Get the app!</strong></span>
                      <br />
                      <span>
                        For the iPhone, tap the App Store icon, for Android, open the Google Play Store. 
                        Type “<strong>ml remit</strong>” into the search bar and tap the <strong>ML Remit by M Lhuillier</strong> icon to start 
                        downloading the app.
                      </span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir="ltr" role="presentation">
                    <span>
                    <strong>Check your status.</strong>
                    </span>
                    <br />
                     <span>
                     This is easy! All you need to do is call <strong>1.800.456.3492</strong> or email us at {" "}
                      <span style={{ color:"#A60202" }}>
                        support@mlhuillierusa.com. {" "}
                      </span> 
                        Please be prepared to provide your transaction number to our customer service representative.
                     </span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir="ltr" role="presentation">
                    <span>
                    <strong>Make sure your recipient has the right ID.</strong>
                    </span>
                    <br />
                    <span>
                    In order to claim the money you send, the recipient will need to bring a government-issued ID, 
                    Integrated Bar of the Philippines Membership Card or ID, Barangay Identification, employment ID 
                    from an institution registered, supervised or regulated by the SEC, BSP, or IC. If the recipient is under <strong>18</strong> and still in school, a student ID is also an acceptable form of identification. Your recipient may also be required to provide supporting documentation, in the form of Birth or Marriage Certificates, Billing,and Bank Statement, or credit cards. 
                    The complete list of accepted ID types can be found on the ML Remit website, {" "}
                    <a href="https://www.mlremit.com/FAQs/accepted_IDs" target='_blank' role='link' title='ml remit accepted ids' style={{ color:"#005C00" }}>
                      ML Remit Accepted IDs.
                    </a>
                    </span>
                  </p>
                  </li>
                </ul>
                <p dir="ltr">
                  It’s as easy as that! Transfers with ML Remit are quick: 
                  Debit and credit transactions are usually available within minutes, 
                  although ACH payments may take up to 4 business days.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                Everything you need to know about Money Transfers via ML Remit of M. LHUILLIER
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir="ltr">
                  If you need to send money to the Philippines, ML Remit provides a convenient, 
                  secure way to do so. You can send money to the Philippines from <strong>50</strong> U.S. states, 
                  and it can be picked up at over <strong>3,000</strong> locations across the Philippines. 
                  Best of all, you can make the transactions right from your computer or phone.
                </p>
                <p dir="ltr">
                  With ML Remit, it’s easy to send money to the Philippines using your bank account, 
                  debit or credit card. When you use your bank account to send money using an ACH transaction, 
                  it only takes about four days for the money to arrive. Does your recipient need it more quickly? Use your debit or credit card, and the money will arrive in minutes. Be aware, however, 
                  that your card issuer might charge an additional fee if you use your card in the transfer.
                </p>
                <p dir="ltr">
                  It’s easy to get started with ML Remit. Just go to our website, {" "}
                  <a href="https://www.mlremit.com/" target='_blank' role='link' title="ml remit" aria-label='ml remit' style={{ color:"#264D97" }}>www.mlremit.com {" "}</a> 
                  and choose “ <strong>Send Now</strong> ”. You’ll be as+ked to enter some information necessary to 
                  create your account, and then you may begin sending money to the Philippines. 
                  If you should have any questions, all you need to do is call us at {" "}
                  <strong>1.877.688.4588</strong> or email us at or email us at {" "}
                  <span href="" role='none' title='mlhuillier support' style={{ color:"#A80000"}}>
                    support@mlhuillierusa.com.
                  </span>
                </p>
                <p dir="ltr">
                  Should you need to cancel a transaction, that’s easy to do, too. If the transfer was made by ACH, 
                  you can cancel on the day you set it up and the money will never leave your account. Even if you 
                  decide to cancel the next day, the money will still be refunded to you. 
                  ACH refunds go directly back into your bank account and are completed within four business days.
                </p>
                <p dir="ltr">
                  ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to 
                  be a world-class institution. As such, we charge fair and competitive rates for our customers, 
                  and consistently seek opportunities for growth and development, while maintaining a mindset of 
                  quality, customer care, and social responsibility. For more information, you can visit our website, 
                  <a href="https://www.mlremit.com/" target='_blank' role='link' title="ml remit" aria-label='ml remit' style={{ color:"#264D97"}}>
                    {" "}www.mlremit.com {" "}
                  </a> 
                  or call us toll-free at <strong>1.844.456.2543.</strong>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                Here’s why you should use ML Remit to send money to the Philippines
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir="ltr">
                  If you have a reason to send money to the Philippines, it can be difficult to choose a money transfer provider. We know there are plenty of providers from which to choose, but we hope you’ll consider us when you’re making your choice. 
                  There are many reasons to use ML Remit when you need to send money to the Philippines.
                </p>
                <ul className="list bold">
                    <li dir='ltr' className="list-item">
                      <p dir="ltr"  role='presentation'>
                        <span>
                          <strong>ML Remit is your bridge, whether you’re looking to connect with loved ones, business associates or friends.</strong>
                        </span>
                        <br />
                      <span>
                      With locations all throughout the Philippines, we’re everywhere you need us to be, ready to serve you. 
                      Visit our website, 
                      <a href="https://www.mlremit.com/" target='_blank' role='link' title="ml remit" aria-label='ml remit' style={{ color:"#264D97" }}>
                        {" "}www.mlremit.com {" "}
                      </a>  
                        to find a branch in the Philippines near your beneficiary.
                      </span>
                    </p>
                    </li>
                    <li dir='ltr' className="list-item">
                      <p dir="ltr"  role='presentation'>
                      <span>
                        <strong>Our customer service is outstanding.</strong>
                      </span>
                      <br />
                      <span>
                      Our customers are our top priority, and we work hard to provide the very best service, 
                      with the intention of making customers for life. Phone: 1.877.688.4588 Email: support@mlhuillierusa.com
                      </span>
                    </p>
                    </li>
                    
                    <li dir='ltr' className="list-item">
                    <p dir="ltr" role='presentation'>
                      <span>
                      <strong>We are fast!</strong>
                      </span>
                      <br />
                      <span>
                      Debit and credit transactions are usually available within minutes. ACH payments may take up to 4 business days.
                      </span>
                    </p>
                    </li>
                    <li dir='ltr' className="list-item">
                      <p dir="ltr" role='presentation'>
                        <span><strong>You can send money to the Philippines for as low as $4.99.</strong></span>
                        <br />
                        <span>
                          Some services have hidden fees, but with ML Remit, you know exactly what you’re paying.
                        </span>
                      </p>
                    </li>
                    <li dir='ltr' className="list-item">
                      <p dir="ltr" role='presentation'>
                        <span>
                        <strong>We make it easy, with the ML Remit App.</strong>
                        </span>
                        <br />
                        <span>
                        From the Apple App Store or Google Play Store, just type <strong>ml remit</strong> into the search bar. 
                        Tap the ML Remit by M Lhuillier icon to start downloading the app.
                        </span>
                      </p>
                    </li>
                    <li dir='ltr' className="list-item">
                        <p dir="ltr" role="presentation">
                          <span><strong>If there’s a problem, it’s easy to get a refund.</strong></span>
                          <br />
                          <span>If you cancel your transaction, we’ll give you a refund. If your transaction was on a debit or credit card, your money will be 
                          refunded to your card. For ACH transfers, which come straight out of your bank account, a same day cancellation means the money 
                          never leaves the account. If you request a refund on a transfer that was made on a previous day, the money will be refunded within four business days.</span>
                        </p>
                    </li>
                </ul>
                <p dir="ltr">
                  We know you have plenty of options, and that’s why ML Remit works hard to earn your business. 
                  ML Remit is a brand of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. 
                  As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and development, 
                  while maintaining a mindset of quality, customer care, and social responsibility. For more information, you can visit our website, 
                  or call us toll-free at 1.877.688.4588.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                How to Send Money to the Philippines
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir="ltr">
                  There are plenty of reasons you might want to send money to the Philippines. 
                  You may be helping family members in their daily needs, tuition and medical issues or you may need to pay 
                  someone working for you. You might be sending money to the Philippines for your monthly payment of a retirement home. 
                  Whatever your reason for needing to send money to the Philippines from the U.S. it’s important to know how to find a 
                  reputable provider.
                </p>
                <p dir="ltr">
                  You can send money to the Philippines in several different ways. You can transfer money directly from your bank account, 
                  or use a debit or credit card. Be aware, however, that your card issuer may charge additional fees if you use your credit 
                  card to initiate a transfer of funds. There are many different providers who can handle this kind of transaction for you, 
                  so it’s a good idea to do some research before choosing one.
                </p>
                <ul className="list bold" >
                  <li dir='ltr' className="list-item">
                    <span>First, you’ll want to compare the cost of service. </span>
                    <span>
                      Some providers charge exorbitant fees to transfer your money. Others hide their fees by offering a poor exchange rate. Look for a provider that offers low-cost service and a favorable rate of exchange.
                    </span>
                  </li>
                  <li dir='ltr' className="list-item">
                    <span>It’s also important to find a money transfer service provider with a good reputation. </span>
                    <span>
                      Find a provider with good customer service and a reputation for honesty and fair dealings. 
                      ML Remit by M Lhuillier is recognized as fast, convenient, and safe.
                    </span>
                  </li>
                  <li dir='ltr' className="list-item">
                    <span>Get a provider that’s fast!</span>
                    <span>
                      There are money transfer providers that offer overseas money transfers in minutes. 
                      Why should your recipient have to wait when modern technology makes transferring money quick and easy?
                    </span>
                  </li>
                  <li dir='ltr' className="list-item">
                    <span>Choose the provider with the fewest obstacles to sending and receiving money. </span>
                    <span>
                      Some providers will only transfer directly to a bank, but it’s better to choose one with very accessible locations. 
                      Ideally, you should choose a provider with many locations for easier and hassle-free retrieval of money.
                    </span>
                  </li>
                  <li dir='ltr' className="list-item">
                    <span>Ultimate convenience- there’s an app for that. </span>
                    <span>
                      The most expedient way to transfer money is through the use of an app. 
                      That’s why well-established money transfer service providers offer this convenience to their customers. 
                      When you’re seeking a provider, look for one with an app.
                    </span>
                  </li>
                </ul>
               <p dir="ltr">
                  When you send money to the Philippines with ML Remit, you get fast, reliable service that’s easy to use. 
                  <strong> M LHUILLIER</strong> is widely recognized all over the world and we have over <strong>3,000</strong> locations in the Philippines so that your 
                  recipient doesn’t have to travel somewhere inconvenient to get the money. When you use a debit or credit card, the money 
                  typically arrives in the Philippines within minutes. Additionally, our apps are available for iPhone and Android, to make 
                  sending money to the Philippines even easier.
               </p>
               <p dir="ltr">
                  ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. 
                  As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and 
                  development, while maintaining a mindset of quality, customer care, and social responsibility. For more information, 
                  you can visit our <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#264D97" }}>FAQ</a> page, or call us toll-free at 1.877.688.4588.
               </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                International money transfers made easy
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir="ltr">
                  There are plenty of reasons to transfer money internationally, but when you think of major world economies, 
                  do you think of the Philippines? Would it surprise you to know that it has the 33rd largest economy in the world? 
                  A relatively new country, the Philippines gained its independence from Spain in 1898, after more than 300 years of 
                  colonization. Agriculture, manufacturing, and services are the main industries in this archipelagic country, and money 
                  routinely is sent between the U.S. and the Philippines. If you find occasion to send money to the Philippines, do you 
                  know how to do it? Here are some tips to make it easier
                </p>
                <ul className="list bold">
                    <li dir='ltr' className="list-item">
                    <p dir="ltr" role="presentation">
                      <span>
                        <strong>Only send money through a reputable provider.</strong>
                      </span>
                      <br />
                      <span>Choose a company with integrity, one that deals honestly with everyone involved in the transfer. 
                      Make sure there are security precautions in place to protect your account.</span>
                    </p>
                    </li>
                  <li dir='ltr' className="list-item">
                  <p dir="ltr" role='presentation'>
                    <span><strong>Choose a provider that gets the money to your recipient quickly.</strong></span>
                    <br />
                    <span>When someone needs money, there’s not always time to wait. Your provider should offer several different options for 
                    sending the money, and some of those options should get it to the recipient within minutes.</span>
                  </p>
                  </li>
                 <li dir='ltr' className="list-item">
                    <p dir="ltr" role='presentation'>
                      <span><strong>Make sure there are multiple avenues for delivering the money.</strong></span>
                      <br />
                      <span>Many money transfer services offer bank deposits, but this is not always a practical option in the Philippines. 
                      In fact, physical conditions in some parts of the Philippines can make even getting to the ATM difficult. Look for 
                      a money transfer service that offers many different locations, so that your recipient can choose the one that is most accessible.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir="ltr" role='presentation'>
                      <span><strong>Tell your recipient to bring the right ID.</strong></span><br />
                     <span> The importance of the correct ID cannot be stressed enough. Before sending money to the Philippines, check the transfer 
                      provider to determine their requirements, and then make sure the person to whom you are sending money is able to meet 
                      those requirements.</span>
                    </p>
                  </li>
                </ul>
                <p dir="ltr">
                  At ML Remit, we offer a secure, convenient way to send money internationally. Transfers with ML Remit are quick: 
                  Debit and credit transactions are usually available within minutes, although ACH payments may take up to 4 business days. 
                  The money can be picked up as cash at any of our <strong>3,000</strong> M. LHUILLIER locations in the Philippines, just by bringing the 
                  transaction number and a valid photo ID.
                </p>
                <p dir="ltr">
                  ML Remit is a brand of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class 
                  institution. As such, we charge fair and competitive rates for our customers, and consistently seek opportunities 
                  for growth and development, while maintaining a mindset of quality, customer care, and social responsibility. 
                  For more information, you can visit our website, or call us toll-free at <strong>1.877.688.4588</strong>.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                Sending money online? Here’s what you should know first.
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p>
                  These days, sending money online is easy. There are many different services available, so that no matter your situation, 
                  there’s a money transfer service to meet your needs. You can use an online money transfer service to transfer the amount of 
                  money you need to send internationally. It’s important to select a service that provides security, speed, ease of use, and 
                  low fees
                </p>
                <p>
                  ML Remit meets all of these requirements, providing service that’s quick and easy and customer service that creates 
                  lifelong customers. It’s easy to register online to send money, using your bank account or card.
                </p>
                <ul className="list">
                  <li dir='ltr' className="list-item">
                    Simply select <strong>Send Now</strong> from the home page, <a href="https://www.mlremit.com/" target='_blank' role='link' title="ml remit" aria-label='ml remit' style={{ color:"#264D97" }}>www.mlremit.com</a>
                  </li>
                  <li dir='ltr' className="list-item">
                    Click “ <em>Sign Up to Send Money Now</em> ”
                  </li>
                  <li dir='ltr' className="list-item">
                    Enter your personal information, and hit the “ <em>Sign Up</em> ” button
                  </li>
                </ul>
                <p>
                  It’s as easy as that! Once you’ve created your account, you’ll be able to enter your recipient’s information. 
                  This service is available in <strong>50</strong> states. When you send money using a credit or debit card, it is available to the 
                  recipient within minutes. Transferring using a bank account, via ACH, can take up to four business days.
                </p>
                <p>
                  Be aware, there is a limit to how much you can send per day. You can send a minimum of <strong>$25</strong> up to a maximum of <strong>$1,999</strong> 
                  per day on the basic account. Note: you may be able to increase your limit by calling customer service at <strong>1.800.456.3492</strong> 
                  to check your eligibility. In some cases, you may be eligible for higher sending limits and shorter available transfer 
                  times.
                </p>
                <p>
                  ML Remit is a brand of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class 
                  institution. As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for 
                  growth and development, while maintaining a mindset of quality, customer care, and social responsibility.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                History of Filipino Americans
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p>
                  Did you know that Filipinos make up the second largest Asian group in the United States? Recent statistics put the number of people who identify as Filipino Americans at about 3.4 million. Filipinos have been in the United States since as early as the 16th century, but how much do you know about their history?
                </p>
                <p>
                  Filipinos are native to or identified with the Philippines. They come from more than 175 different ethnolinguistic groups, but the modern Filipino identity has Austronesian roots, with Spanish, Chinese and American influences. Contemporary Filipino culture is heavily influenced by Spain because the country was a Spanish colony for 333 years. In fact, use of the term “Filipino” was adopted during the Spanish colonial period, the country having been named the Philippine Islands by Ruy López de Villalobos, a Spanish explorer and Dominican priest.
                </p>
                <p>
                  After Spain was defeated in the Spanish American War in 1898, Emilio Aguinaldo, a Filipino general declared the independence of the Philippines. When the Treaty of Paris officially ended the war, Spain ceded control of the Philippines to the United States. William Howard Taft became the first American Governor-General of the Philippines in 1901. The country gained independence from the United States in 1946.
                </p>
                <p>
                  While small numbers of Filipinos lived in what is now the United States as early as the 16th century, mass migration did not begin until the early 20th century, when the Philippines were considered part of the United States. One reason that Filipinos have such a large presence in the United States is that while the Philippines was a US territory, immigration between the Philippines and the US was unrestricted. As mentioned earlier, there are currently about 3.4 million Filipino-Americans. There are also about 300,000 American citizens currently residing in the Philippines.
                </p>
                <p>
                  Filipinos have had a major impact on United States culture. In the early 1920s, many Filipinos, known as manongs, went to the United States to find farm work. They settled primarily in Hawaii, California, Washington,and Alaska, and they were influential in helping farm workers form unions, in order to fight for better pay and conditions for workers. About a quarter of a million Filipinos fought with the US in World War II,and the ‘60s and ‘70s saw Filipino professionals leaving their country to fill gaps in the United States workforce. Many of these people took on important jobs in healthcare and technology, which helped to promote the development of our country’s health and prosperity.
                </p>
                <p>
                  Today, Filipino Americans are dispersed across the United States, though the highest concentrations are still in California and Hawaii. Overall, they’re better educated than other ethnicities, and slightly better off financially. In 2017, Filipinos living outside of the Philippines sent back $33 billion in remittances to their loved ones in the Philippines, which is more than double what was sent just a decade ago.
                </p>
                <p>
                  ML Remit is a proudly Filipino business, with over <strong>2,500</strong> locations across the Philippines, helping people send money to the Philippines from the US quickly and easily. The company is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and development, while maintaining a mindset of quality, customer care, and social responsibility. For more information, you can visit our <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#274F9B" }}>website</a> , or call us toll-free at <strong>1.877.688.4588.</strong>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                How to get started with ML Remit
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p>
                  ML Remit is a quick, easy, inexpensive way to send money to the Philippines from the US. 
                  It allows you to send money using a bank account or credit/debit card. You can send up to $1,999 per day on the basic account 
                  (Silver), and it never costs your beneficiary a penny. Are you ready to get started? It’s as simple as setting up an account.
                </p>
                <ul className="list bold" role="presentation">
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>First, visit ML Remit’s homepage.</strong></span><br />
                     <span> Click the <strong>Send Now</strong> button on the ML Remit homepage and you’ll be taken to a page with an easy to use form. 
                      Fill in some basic information, like your name, email address, and phone number, and you’ll be ready to start 
                      sending money! Once you’ve chosen your username and password, and verified your account, you can enter your 
                      beneficiary’s information and get started.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item" >
                    <p dir='ltr' role='presentation'>
                      <span><strong>If you prefer, we have an app.</strong></span><br />
                      <span>From the App Store on the iPhone or the Google Play Store on an Android phone, type ml remit into the search bar. Tap the <strong>ML Remit by M Lhuillier</strong> icon to start downloading the app. Using the app, you can create a list of recipients, easily send money, check the status of your transaction, and view your history.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Your beneficiary has plenty of options for pickup.</strong></span><br />
                      <span>We’ve got over <strong>3,000</strong> locations across the Philippines, so there’s sure to be one that’s convenient for your recipient to visit. With the proper ID, your beneficiary can pick up the money you send at any time because we’re open <strong>24</strong> hours a day.</span>
                    </p>
                  </li>
                </ul>
                <p dir='ltr'>
                  ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. 
                  As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and 
                  development, while maintaining a mindset of quality, customer care, and social responsibility. For more information, 
                  you can visit our <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#274F9B" }}>website</a> page, or call us toll-free at <strong>1.877.688.4588</strong>.
               </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                How we keep your money safe and secure
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p  dir='ltr'>
                  If you’re sending money to friends and family in the Philippines, do you worry about the security of your transactions? If you send money to the Philippines from the US through ML Remit, you need not have any concerns about its safety. We offer secure, convenient, fast money transfers, and you can send money using your bank account or credit/debit card, with transfers that go through almost immediately.
                </p>
                <p dir='ltr'>
                  ML Remit adheres to federal regulations for remittance transfer providers. These regulations require us to:
                </p>
                <ul className="list">
                  <li dir='ltr' className="list-item">
                    Provide information about the exchange rate, our fees and taxes, and the amount of money expected to be delivered.
                  </li>
                  <li dir='ltr' className="list-item">
                    Offer clear information about when the money will be available, and how to submit a complaint, cancel a transfer, or respond to an error.
                  </li>
                  <li dir='ltr' className="list-item">
                    Give you a chance to cancel the transfer within thirty minutes if you need to do so, at no charge.
                  </li>
                  <li dir='ltr' className="list-item">
                    Investigate any errors that may occur, and notify you of our findings.
                  </li>
                </ul>
                <p>
                  Complying with federal regulations is one way we work hard to keep your money safe and secure, but it’s not the only way. Another step we take is to require the proper identification from anyone coming to one of our Philippines locations to collect money. Acceptable forms of identification include:
                </p>
                <ul className="list bold">
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Government-issued IDs:</strong></span><br />
                      <span>These include an Alien Certification of Registration, Immigrant Certification of Registration, Driver’s License issued by the Philippine government, 
                      Government Service Insurance System Electronic ID card, an Overseas Worker pass, or a passport. They also include Police Clearance Certificates, 
                      Professional Regulation Commission ID, Seaman’s Book and Seafarer’s Registration Certificate, Senior Citizen’s ID, Social Security System ID with 
                      photo, Voter’s ID, and otherGovernment Office and GOCC IDs.</span>
                    </p>
                  </li>
                 <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong> Other acceptable forms of ID:</strong></span><br />
                      <span>In addition to these government IDs, you can also provide an Integrated Bar of the Philippines Membership Card, or Barangay Identification.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Employment ID:</strong></span><br />
                      <span>You can only use your employment ID is your company is registered, supervised, or regulated by the Securities and Exchange Commission, 
                      BangkoSentral ng Pilipinas, or the Insurance Commission.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                  <p  dir='ltr' role='presentation'>
                    <span><strong>School ID:</strong></span><br />
                    <span>Recipients who are minors can use their school ID to pick up their money. The first time, however, they are asked to provide documentation of 
                    their current school enrollment, such as an enrollment card, receipts from the school, or assessment strips.</span>
                  </p>
                  </li>
                </ul>
                <p>
                  Principally based in the Philippines, with over 2500 locations throughout that country, ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and development, while maintaining a mindset of quality, customer care, and social responsibility. For more information, you can visit our <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#274F9B" }}>website</a>, or call us toll-free at <strong>1.877.688.4588</strong>.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                School Enrollment and Money Transfers
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p>
                  In the Philippines, school enrollment is underway for the session that begins in June. 
                  School enrollment periods are busy times for ML Remit because transactions are high as money 
                  is sent to promote education. Often, a lack of money is all that stands between a child and learning, 
                  so money is sent, whether by loved ones or charitable organizations, to provide children with access 
                  to healthy meals, school supplies, and an education.
                </p>
                <p>
                  M. Lhuillier, ML Remit’s parent company, participates in educational outreach programs. 
                  Through the work of these programs, children are provided backpacks packed with supplies 
                  like notebooks, pencils, and pencil cases. The schools are given building materials to help 
                  build necessary additions, and also supplies like computers, printers, and ceiling fans.
                </p>
                <p>
                  If you have loved ones in the Philippines who have school-aged children, you may be looking for 
                  a meaningful way to help them get a good education. If you want to send money to help promote 
                  their school success, ML Remit can help. We offer secure, convenient, fast money transfers from 
                  all fifty states, in order to help you send money to the Philippines from the US.
                </p>
                <p>
                  It’s easy to do. Simply go online or use the app, to send money by bank account or credit/debit card. 
                  Money sent by card will arrive in the Philippines within minutes, and bank transfers may take up to 
                  four days. The exchange rate is good, and you only pay a low, flat fee of <strong>$4.99</strong>.
                </p>
                <p>
                  Your recipient doesn’t have to pay any fee at all. All the beneficiary of your transfer has to do is 
                  visit one of our 3,000 locations in the Philippines, bringing the proper ID, and collect the money 
                  you have sent. Some of our locations are open <strong>twenty-four</strong> hours a day, so there’s never any rush to 
                  get there before closing time. The money can be used however you want, whether it’s to provide for 
                  school enrollment or meet some other need.
                </p>
                <p>
                  ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and development, while maintaining a mindset of quality, customer care, and social responsibility. For more information, you can visit our <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#274F9B" }}>website</a>, or call us toll-free at <strong>1.877.688.4588</strong>.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                Step by step guide for setting up ML Remit account online
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p>
                  Did you know that with ML Remit, it’s easy to send money to the Philippines from US bank accounts? All you have to do is go online and set up an ML Remit account, by following a few simple steps.
                </p>
                <ul className="list bold">
                  <li dir='ltr' className="list-item" >
                  <p dir='ltr' role='presentation'>
                    <span><strong>First, go to the <span style={{ color:"#A80000" }}>ML Remit’s homepage.</span></strong></span>
                    <br />
                    <span>Click " <strong>Send Now</strong> ".</span>
                  </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Choose to create an account.</strong></span>
                      <br />
                      <span> On the next page you reach after clicking the appropriate button, you’ll see another button labeled “ <strong>Sign Up to Send Money Now</strong> ”. 
                      Click that button, and it will take you to a sign up form.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Fill in your information.</strong></span>
                      <br />
                      <span>The sign up form is self-explanatory, with blanks for your name, your email address, and your phone number. Fill in the blanks and submit the form.
                       You’ll then be taken to another page, where you’ll create a username and password and choose a security question.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Verify your account.</strong></span>
                      <br />
                      <span> ML Remit will send an email to the address you’ve given us, and you’ll be given a verification code. Enter that, and you’re ready to start 
                      sending money. It’s as easy as that!</span>
                    </p>
                  </li>
                </ul>
                <p dir='ltr'>
                  Once you have set up your account, you can immediately enter your recipient’s information. 
                  Money sent from your account using a card will be available to the recipient within minutes, 
                  while money sent using a bank account and ACH can take up to four days to transfer. The person 
                  in the Philippines to whom you are sending the money may pick it up at any of our 2,500 locations, 
                  just by showing the proper identification. There’s never any fee for your beneficiary, and only a 
                  small fee of <strong>$4.99</strong> for you. You can send up to <strong>$1,999</strong> per day via card or bank account with a monthly 
                  limit of <strong>$4,999</strong>. If you need to send more than that, call our customer service line to determine if 
                  you’re eligible for an increase.
                </p>
                <p dir='ltr'>
                  ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. As such, 
                  we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and development, while maintaining a 
                  mindset of quality, customer care, and social responsibility. For more information, you can visit our {" "}
                  <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#274F9B" }}>website</a>, or call us toll-free at 
                  <strong>1.877.688.4588</strong>.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                The fast, simple way to send money to friends and family
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir='ltr'>
                  If you need to send money overseas to friends and family, it’s hard to know which institution to choose. 
                  You need a company that offers secure transfers and will get the money there quickly, but it’s also 
                  important that the process is simple and easy to use. If you need to send money to the Philippines 
                  from the US, ML Remit is the answer.
                </p>
                <ul className="list bold">
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Fast:</strong></span><br />
                      <span>You can send up to <strong>$1,999</strong> per day using your card, and the money will be available within minutes. 
                      If you choose to send money through your bank account via ACH, you can still send <strong>$1,999</strong> per day, 
                      but it may take up to four days to arrive.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Simple:</strong></span><br />
                      <span>To start sending money, you can simply visit ML Remit’s website and create an account. 
                      Once you’ve entered your information, you can begin to send money to the Philippines from 
                      the US immediately.</span>
                    </p>
                  </li>
                 <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span>
                        <strong>Cost Effective:</strong>
                      </span>
                      <br />
                      <span>ML Remit offers a very good exchange rate, and the charge to send money to the Philippines is only <strong>$4.99</strong>.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Secure:</strong></span>
                      <br />
                      <span>For your protection, we only release the money to the designated beneficiary. Collecting the money requires a government issued ID, an ID 
                      issued by an employer affiliated with the Securities and Exchange Commission (SEC), BangkoSentral ng Pilipinas (BSP), or the Insurance Commision (IC).
                      A minor receiving money can display a student ID, as long as it’s supported by additional official documents, like a birth certificate or a bank statement.</span>
                    </p>
                  </li>
                  <li dir='ltr' className='list-item'>
                    <p dir='ltr' role='presentation'>
                      <span><strong>Convenient:</strong></span>
                      <br />
                     <span> Our service is available using a bank account or debit/credit card in all fifty of the United States. 
                      If you live in California, you also have the option of sending cash. We offer cash pickup at more than 
                      <strong>2,500</strong> locations in the Philippines, so there’s sure to be a location that’s convenient for the person 
                      to whom you want to send money. Some of these locations are open <strong>24</strong> hours, so your recipient never has 
                      to worry about getting there before they close.</span>
                    </p>
                  </li>
                </ul>
                <p dir='ltr'>
                  Principally based in the Philippines, with over 3,000 locations throughout that country, ML Remit is part of M. Lhuillier Financial Services, a financial establishment working hard to be a world-class institution. As such, we charge fair and competitive rates for our customers, and consistently seek opportunities for growth and development, while maintaining a mindset of quality, customer care, and social responsibility. For more information, you can visit our <a href="/faq" target="_blank" role='link' title='faq' aria-label='faq' style={{ color:"#274F9B" }}>website</a> page, or call us toll-free at <strong>1.877.688.4588</strong>.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                Different Ways to Send Money to the Philippines
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p><strong>How to Send Money to the Philippines</strong></p>
                <p>
                  ML Remit is here to help you learn how to send money to the Philippines in the most convenient way possible. Say goodbye to high prices and slow transfers—our service makes the entire process safe and simple. We are brought to you by the trusted M Lhuillier name, so you can feel confident working with us. Whether you want to send something for Christmas, Mother’s Day, or another special occasion, we’re happy to make it a simple task.
                </p>
                <p>
                  ML Remit provides easy channels for those who are trying to transfer money internationally between the United States and the Philippines. You can use a debit card, a credit card, or a bank account—whatever is easiest for you.
                </p>
                <p>
                  Our company is known for providing fast, reliable money transfer service. Let us show you how easy it can be to send a wire transfer or a payment without a hassle or unreasonable expenses. You can even send money to yourself in the Philippines to avoid carrying cash while you visit! Feel free to reach out if you have any questions, or just download our easy-to-use app!.
                </p>
                <ol className='list bold' role='list'>
                 <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Send money from a credit or debit card</strong></span>
                      <br />
                      <span>If you would like to send money to the Philippines using a credit or debit card, ML Remit can help. Our fast, secure process is the best way to send 
                      money to the Philippines, and we are proud to share it with you. The setup to send money with a debit or credit card is basically the same. 
                      Create your account, add your information, and you’re ready to go. You can send money right away.</span>
                    </p>
                  </li>
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Send money from a bank account</strong></span>
                      <br />
                      <span>Not everyone likes the idea of giving a debit or credit card number out — although we can assure you our system is quite secure. 
                      Even so, some people feel the best way to send money to family, friends, or businesses in the Philippines is to transfer it from their bank account. 
                      ML Remit can assist you with this as well. Simply set up an online account with us. It doesn’t take long to fill out the information needed to get 
                      things going. Once you have everything finished, we will walk you through each step needed to transfer money from your bank account to a destination 
                      in the Philippines. You can use either your checking or savings account, so the process is very convenient.</span>
                    </p>
                  </li>
                </ol>
                <p dir='ltr'>
                  <strong>
                    Trustworthy and easy!
                  </strong>
                </p>
                <p dir='ltr'>
                  We have a long list of highly satisfied customers throughout all 50 states in the U.S. This is also true of our clients in the Philippines. We’re brought to you by the experts at MLhuillier, a trusted name in the industry.
                </p>
                <p dir='ltr'>
                  Download the ML Remit app today!
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item tabIndex="-1" eventKey="0">
              <Accordion.Header>
                Security
              </Accordion.Header>
              <hr />
              <Accordion.Body>
                <p dir='ltr'>
                  <strong>Safest Way to Send Money to the Philippines and the U.S.</strong>
                </p>
                <p dir='ltr'>
                  ML Remit is excited to share with you what we believe is the safest way to send money to the Philippines and the U.S. Our company has worked hard to make a simple, secure process for wire transfers and other international payments, and we’ve got great solutions for our customers. We send money from the U.S. all the time, and our clients are very happy with our services. It doesn’t matter if you want to make a payment using your credit card or your bank account, we give you a number of choices that are all very secure.
                </p>
                <ul className="list bold">
                  <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Safe and secure</strong></span>
                      <br />
                      <span>The number one concern of people doing wire transfers or sending money internationally is the safety and security involved in the transaction. 
                      We understand that, and we have taken many steps to make absolutely sure there won’t be any problems when you sign up or send money! If you don’t 
                      usually send money online, we want you to know we take your security very seriously, and you are safe with us. You can send money online from the U.S. 
                      to the Philippines very quickly, and all without worry. We have a multi-layered security system that has more than one way to check and double-check your account
                       safety and make sure there haven’t been any breaches or issues. Not only that, but we have an advanced alert system that will let us know if anything at all seems 
                       out of order. We stay on top of everything, so you can feel good about our services. We know how important it is to our customers.</span>
                    </p>
                  </li>
                 <li dir='ltr' className="list-item">
                    <p dir='ltr' role='presentation'>
                      <span><strong>Anti-fraud technology</strong></span>
                      <br />
                      <span>ML Remit takes advantage of all the latest anti-fraud technology to make sure you are protected against illegal use of your account. 
                      We stay up-to-date on this subject, as we know it’s critical to our business and to your peace of mind. Thankfully, we are able to draw upon the 
                      considerable expertise of our parent company, M Lhuillier, since they already have a great deal of knowledge in this area.
                      M Lhuillier has been established for many years as one of the go-to experts in this field, and ML Remit is under their umbrella. 
                      This is something that we know inspires confidence in our money remittance services, and it should! The next time you want to send money to the 
                      Philippines from the U.S., make sure you download our app. You’ll be glad you did.</span>
                    </p>
                  </li>
                </ul>
                <p dir='ltr'>
                  Sign up for your secure account today!
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </div>
        </section>
      </div>
    </div>
  )
}

export default BlogComponent;